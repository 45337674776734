import { useState } from "react";
import { useSelector } from "react-redux";
import { useGeneralAlert } from "../../../../hooks/useGeneralAlert";
import { resizeFile } from "../../../../utils/files/resizeFile";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { ArrowBackIos, ArrowForwardIos, ExitToApp } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import Loading from "../../../Loading";
import axios from "../../../../axios/axios.config";
import FlexWrapper from "../../../FlexWrapper";
import EditDiscrepanciesModal from "../../../warehouse/pack-process/update-pack-order/EditDiscrepanciesModal";
import ViewAWSImages from "../../../warehouse/pack-process/update-pack-order/ViewAWSImages";
import GeneralFileUpload from "../../../GeneralFileUpload";

const UpdatePackOrderResultsV1 = ({ data }) => {
  const navigate = useNavigate();
  const navigation = useNavigation();
  const { openAlert } = useGeneralAlert();
  const { orderid } = useParams();
  const { username, currentDepartment } = useSelector((state) => state.user);

  const [palletData, setPalletData] = useState({ ...data?.packDetails });
  const [currentPallet, setCurrentPallet] = useState(data?.currentPallet);
  const [loading, setLoading] = useState("");
  const [discrepancies, setDiscrepanices] = useState(data?.discrepancies || 0);

  const currentImages = palletData[currentPallet]?.originalImages;

  if (navigation.state === "loading") {
    return <Loading message="Navigating..." />;
  }

  if (loading) {
    return <Loading message={loading} />;
  }

  const handleSaveFiles = async (files) => {
    try {
      const resizedFiles = [];
      for (const f of files) {
        const resizedFile = await resizeFile({
          file: new File(
            [f],
            `${currentPallet.replace("pallet", "Pallet_")}_${Date.now()}.`,
            {
              type: f.type,
              lastModified: f.lastModified,
            }
          ),
          maxWidth: 2500,
          maxHeight: 2500,
          compressFormat: "jpeg",
          quality: 80,
          outputType: "file",
        });
        resizedFiles.push(resizedFile);
      }
      const newPalletData = { ...palletData };
      //update images for current pallet
      newPalletData[currentPallet].newImages = [
        ...newPalletData[currentPallet].newImages,
        ...resizedFiles,
      ];

      newPalletData[currentPallet].isupdated = true;
      setPalletData(newPalletData);
    } catch (error) {
      openAlert({
        type: "error",
        message: `Error: ${error.message}`,
        duration: 3000,
      });
    }
  };

  const handleRemoveFile = (file) => {
    const newPalletData = { ...palletData };
    //update images for current pallet
    newPalletData[currentPallet].newImages = newPalletData[
      currentPallet
    ].newImages.filter((fileObj) => fileObj.name !== file.name);

    setPalletData(newPalletData);
  };

  const handleInputChanges = (event) => {
    const newData = { ...palletData };
    if (
      event.target.name === "discrepancies" ||
      event.target.name === "weight"
    ) {
      newData[currentPallet][event.target.name] = parseInt(event.target.value);
    } else {
      newData[currentPallet][event.target.name] = event.target.value;
    }
    newData[currentPallet].isupdated = true;
    setPalletData(newData);
  };

  const handlePrevPallet = () => {
    if (currentPallet === data?.palletKeys[0]) return;
    const palletNum = parseInt(currentPallet.replace("pallet", ""));
    setCurrentPallet(`pallet${palletNum - 1}`);
  };

  const handleNextPallet = () => {
    if (currentPallet === data?.palletKeys[data?.palletKeys?.length - 1])
      return;

    const palletNum = parseInt(currentPallet.replace("pallet", ""));
    setCurrentPallet(`pallet${palletNum + 1}`);
  };

  //will update mongo and update netsuite
  const handleUpdatePalletData = async () => {
    setLoading("Updating Pallet Details...");

    const updatedPallets = data?.palletKeys?.filter(
      (pallet) => palletData[pallet].isupdated
    );
    let errorPallet = null;

    try {
      let response = null;
      //if no new updates, just pull latest from mongo and update netsuite
      if (!updatedPallets.length) {
        response = await axios.get(`pack-process/update-pack-order/${orderid}`);
      } else {
        //updates were made
        const newMongoData = {};
        //loop through updated pallets and check rquirements and save files to S3
        for (const pallet of updatedPallets) {
          const currentPalletObj = palletData[pallet];
          if (!currentPalletObj?.weight || currentPalletObj?.weight <= 1) {
            errorPallet = pallet;
            throw new Error(
              `Incorrect weight for ${pallet.replace("pallet", "Pallet")}"`
            );
          }

          if (!currentPalletObj?.dimensions) {
            errorPallet = pallet;
            throw new Error(
              "Pallet data is missing required fields:  Dimensions"
            );
          }
          //only errors out when images are required. Only checks pallets that are updated by user, others ignored
          if (
            data?.isImagesRequired === "T" &&
            currentPalletObj?.newImages?.length < 4
          ) {
            errorPallet = pallet;
            throw new Error(
              `${pallet.replace(
                "pallet",
                "Pallet "
              )} requires at least 4 images`
            );
          }

          //Save File to S3
          const imageKeys = [];
          const formData = new FormData();
          const folder = `warehouse/packprocess/palletimages/${data?.order}/`;
          formData.append("folder", folder);
          if (currentPalletObj?.newImages.length) {
            currentPalletObj?.newImages.forEach((file) => {
              const imageKey = `${file.name}`;
              //will store keys in mongo to generate URLs once saved
              imageKeys.push(folder + imageKey);
              formData.append("files", file);
            });

            //upload images to s3
            await axios.post("/files/upload/multi", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });
          }

          newMongoData[pallet] = {
            weight: currentPalletObj?.weight,
            dimensions: currentPalletObj?.dimensions,
            images: [...palletData[pallet].originalImages, ...imageKeys],
          };
        }

        //update mongo with new mongo Data
        response = await axios.patch(
          `pack-process/update-pack-order/${orderid}/`,
          { newMongoData, updatedPallets }
        );
      }

      if (!response)
        throw new Error("Could not get order data. Please try again.");

      //Use Updated data to make netsuite request
      //check if tran packages record exists
      const tranRecordCheckResponse = await axios.get(
        `netsuite/pack-process/get/transaction-packages/${response.data?.createdfrom}/${response.data?.shipmentsequence}`
      );

      const tranPckgData = tranRecordCheckResponse.data;
      //only delete if tran packages exist
      if (tranPckgData.count) {
        const packageId = tranPckgData.items[0].id;
        await axios.delete(
          `netsuite/pack-process/delete/transaction-packages/${packageId}`
        );
      }

      //create tran packages record (this is where we set header fields)
      const createTranPackageResponse = await axios.post(
        "netsuite/pack-process/create/transaction-packages",
        {
          name: response.data?.createdfrom,
          username,
          shipSequence: response.data?.shipmentsequence,
          discrepancies: response.data?.discrepancies,
        }
      );
      //tran packages record id
      const recordid = createTranPackageResponse.data?.recordid;

      //create the pallets linked to new recordid (set pallet level fields)
      for (const pallet of Object.keys(response.data?.packdetails)) {
        await axios.post(
          "netsuite/pack-process/create/transaction-packages/pallet",
          {
            recordid, //Internal ID of the transaction packages record created in previous step
            palletnumber: parseInt(pallet.replace("pallet", "")), //Pallet Number as Integer
            palletweight: parseFloat(
              response.data?.packdetails[pallet]?.weight
            ), //Pallet Weight as Float
            palletdim: response.data?.packdetails[pallet]?.dimensions, //Pallet Dimensions as String
          }
        );
      }

      //send a request to force tran packages page to update on netsuite to build tran packages breakdown
      await axios.patch("netsuite/pack-process/update/transaction-packages", {
        recordid,
      });

      navigate(`/portal/${currentDepartment?.toLowerCase()}/update-pack-order`);
      openAlert({
        type: "success",
        message: `Successfully Updated ${data?.order}`,
        duration: 5000,
      });
    } catch (error) {
      if (errorPallet) {
        setCurrentPallet(errorPallet);
      }
      openAlert({
        type: "error",
        message: `Could Not Update Data: ${
          error.response?.data?.msg || error.message
        }`,
        duration: 10000,
      });
    } finally {
      setLoading("");
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" textAlign="center" gutterBottom>
        {data?.order}
      </Typography>

      <FlexWrapper gap={1}>
        <IconButton
          onClick={() =>
            navigate(
              `/portal/${currentDepartment?.toLowerCase()}/update-pack-order`
            )
          }
        >
          <ExitToApp color="error" />
        </IconButton>

        {/* Discrepanices Modal*/}
        <EditDiscrepanciesModal
          discrepancies={discrepancies}
          setDiscrepanices={setDiscrepanices}
          openAlert={openAlert}
          id={orderid}
        />
      </FlexWrapper>

      {/* Pallet Changer goes here */}
      <Box display="flex" alignItems="center" justifyContent="center" py={2}>
        <IconButton
          disabled={currentPallet === data?.palletKeys[0]}
          onClick={handlePrevPallet}
        >
          <Tooltip title="Previous" arrow placement="top">
            <ArrowBackIos />
          </Tooltip>
        </IconButton>

        <FormControl variant="outlined" sx={{ minWidth: 200, mx: 1 }}>
          <Select
            labelId="pallet-select-label"
            value={currentPallet?.replace("pallet", "Pallet ")}
            onChange={(event) =>
              setCurrentPallet(event.target.value.replace("Pallet ", "pallet"))
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
          >
            {data?.palletKeys?.map((pallet) => (
              <MenuItem
                key={pallet}
                value={pallet?.replace("pallet", "Pallet ")}
              >
                {pallet?.replace("pallet", "Pallet ")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <IconButton
          onClick={handleNextPallet}
          disabled={
            currentPallet === data?.palletKeys[data?.palletKeys?.length - 1]
          }
        >
          <Tooltip title="Next" arrow placement="top">
            <ArrowForwardIos />
          </Tooltip>
        </IconButton>
      </Box>

      <Box pt={1}>
        <Typography variant="h5" gutterBottom pb={1}>
          Pallet Information{" "}
          <Typography component="span" variant="caption" fontWeight="bold">
            (Pallet Qty: {palletData[currentPallet].totalitemquantity} )
          </Typography>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              required
              type="number"
              label="Pallet Number"
              InputLabelProps={{
                shrink: true,
              }}
              value={currentPallet?.replace("pallet", "")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              label="Enter Weight"
              name="weight"
              type="number"
              inputMode="numeric"
              inputProps={{ min: 1 }}
              value={palletData[currentPallet]?.weight}
              onChange={handleInputChanges}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              name="dimensions"
              label="Enter Dimensions"
              type="text"
              value={palletData[currentPallet]?.dimensions}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleInputChanges}
            />
          </Grid>
        </Grid>
      </Box>

      <Box pt={2}>
        <FlexWrapper alignItems="center" gap={1}>
          <Typography variant="h5" gutterBottom>
            Images
          </Typography>
          {currentImages.length ? (
            <ViewAWSImages
              currentImages={currentImages}
              openAlert={openAlert}
            />
          ) : null}
        </FlexWrapper>

        {data?.palletimages === "T" ? (
          <Typography color="error" variant="caption" pb={1} fontWeight="bold">
            Images are required for this order
          </Typography>
        ) : null}

        <>
          <GeneralFileUpload
            multiple
            handleSaveFiles={handleSaveFiles}
            accept=".jpg,.jpeg,.png"
            handleRemoveFile={handleRemoveFile}
            files={data?.packDetails[currentPallet]?.newImages}
          />
          <Typography
            variant="caption"
            pt={1}
            fontWeight="bold"
            textTransform="capitalize"
          >
            Upload images of each side of the pallet
          </Typography>
        </>
      </Box>

      <Box py={2}>
        <Button
          fullWidth
          variant="contained"
          size="large"
          onClick={handleUpdatePalletData}
        >
          Update Pallets
        </Button>
      </Box>
    </Container>
  );
};
export default UpdatePackOrderResultsV1;
